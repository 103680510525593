import React from 'react'

import flash from '/lib/flash'
import humanize       from 'underscore.string/humanize'

# components
import { Link } from 'react-router-dom'
import ReactMarkdown  from 'react-markdown'

import {
  ResponsiveContainer
  ComposedChart
  Bar
  XAxis
  YAxis
  Tooltip
} from 'recharts'
import Spinner    from '/components/spinner'
import Empty      from '/components/empty'
import HeroStats  from '/components/hero-stats'
import Note       from '/components/note'
import Toggle     from '/components/toggle'

import FaRefresh from 'react-icons/lib/fa/refresh'
import FaCalendar from 'react-icons/lib/fa/calendar'
import FaCloudDownload  from 'react-icons/lib/fa/cloud-download'

import find from 'underscore-es/find'

import {
  withListing
  withActiveNotes
  withReport
  withSyncGilaData
  withSyncAllInPricingData
  withRPC
} from './queries'

export default \
withListing \
withActiveNotes \
withReport \
withSyncGilaData \
withSyncAllInPricingData \
withRPC \
class Summary extends React.Component
  constructor: ->
    super arguments...
    @state =
      gila_sync_loading: false


  syncGilaData: =>
    return unless confirm "This will re-sync all tickets and cost items sold for this listing, which could be significant.\n Are you sure you want to continue?"

    @setState gila_sync_loading: true

    { errors } = await @props.syncGilaData @props.listing.id
    if errors?.length
      flash.error errors
    else
      flash.success "Gila data sync enqueued"
    @setState gila_sync_loading: false

  syncAllInPricingData: =>
    { errors } = await @props.syncAllInPricingData @props.listing.id
    if errors?.length
      flash.error errors
    else
      flash.success "All In Pricing data sync succeeded"

  rpc: (method)=>
    {listing, errors} = await @props.rpc @props.listing.id, method
    if errors?.length
      flash.error errors
    else
      flash.success "Listing updated successfully"

  render: ->
    return <Spinner/> if @props.listingLoading
    return <Empty name="Listing" /> unless @props.listing?.id
    <div className="Summary">
      {@notes()}
      {@reports()}
      {@virtualInfo()}
      {@contactDetails()}
      {@details()}
      {@reportExports()}
      {@actions()}
      {@settings()}
    </div>

  notes: ->
    return null unless @props.notes?.length
    <div className="notes">
      {<Note key={note._key} note={note}/> for note in @props.notes}
    </div>

  details: ->
    <div className="details">
      {@features()}
      {@payment_methods()}
      {@presales()}
    </div>

  features: ->
    @tags 'Features', """
      has_seats
      needs_approval
      all_in_pricing
      allow_on_mfol
      hidden_from_ticketmaster
      allow_waitlist
      invoice
      hide_date
      invoice_fee_consent
      hide_from_widgets
      urgency_indicator_enabled
      show_tickets_available_count
      spam_reviewed
      fan_facing_move_order
      booking_protect
      ln_all_in_pricing
      using_new_checkout
    """.split /\W/

  presales: ->
    @tags 'Enabled Presales', """
      american_express_only
      diners_club_only
      discover_only
      jcb_only
      mastercard_only
      unionpay_only
      visa_only
    """.split /\W/

  payment_methods: ->
    @tags 'Alternative Payment Methods', """
      alipay
      bancontact
      eps
      giropay
      globee
      ideal
      masterpass
      multibanco
      przelewy
      sepa
      sofort
      visacheckout
      wechat
    """.split /\W/

  tags: (name, attrs=[])=>
    <div className="detail #{name}">
      <h3>{name}</h3>
      <div className="panel panel-default">
        <div className="panel-body">
          {for attr in attrs
            <span
              key={attr}
              className="label label-info"
              disabled={not @props.listing[attr]}
            >
              {humanize attr}
            </span>
          }
        </div>
      </div>
    </div>

  reports: ->
    return <Spinner/> unless report = @props.report
    <div className="reports">
      {@salesOverview report}
      {@salesByDateChart report.sales_by_date}
    </div>

  actions: ->
    <div className="actions">
      <h2>Actions</h2>
      <ul>
        <li>
          <a className="btn btn-default" onClick={@syncGilaData} disabled={@state.gila_sync_loading}>
            <FaRefresh />
            Sync Gila Data
          </a>
        </li>
        <li>
          <a className="btn btn-default" onClick={@syncAllInPricingData}>
            <FaRefresh />
            Sync All In Pricing
          </a>
        </li>
        <li>
          <a className="btn btn-default" onClick={@rpc.bind @, 'build_and_sync_calendars'}>
            <FaCalendar />
            Sync Calendar Data
          </a>
        </li>
        { unless process.env.NODE_ENV is 'production'
          <li>
            <a className="btn btn-default" onClick={@rpc.bind @, 'admin_force_settle!'}>
              Force Settlement
            </a>
          </li>
        }
      </ul>
    </div>

  settings: ->
    <div className="settings">
      <h2>Settings</h2>
      <ul>
        <li>
          { @props.listing.country_code != 'US' && <p>Live Nation All In Pricing cannot be enabled for listings outside of the US.</p> }
          <Toggle
            value={!!@props.listing.ln_all_in_pricing}
            onChange={@rpc.bind @, 'toggle_ln_all_in_pricing!'}
            disabled={@props.listing.country_code != 'US'}
          />
          Live Nation All In Pricing
        </li>
        <li>
          <Toggle
            value={!!@props.listing.using_new_checkout}
            onChange={@rpc.bind @, 'toggle_using_new_checkout!'}
          />
          Using new checkout
        </li>
      </ul>
    </div>

  virtualInfo: ->
    return null unless info = @props.listing.virtual_info
    <div className="virtual_info">
      <h2>Virtual event info</h2>
      <blockquote>
        <ReactMarkdown
          className="text"
          source={info}
        />
      </blockquote>
    </div>

  contactDetails: ->
    return null unless cd = @props.listing.contact_details
    <div className="contact-details">
      <h2>Host contact details</h2>
      <blockquote>
        {cd}
      </blockquote>
    </div>

  salesOverview: (report)->
    return <Spinner/> unless report
    <div className="sales-overview">
      <h2>Sales Overview</h2>
      <HeroStats
        data={[
          name: 'Orders'
          value: report.tickets_sold
        ,
          name: 'Guests'
          value: report.ticket_items_sold
        ,
          name: 'Total Sales'
          value: report.amountGross
        ,
          name: 'Net Sales'
          value: report.fulfillmentAmount
        ]}
      />
    </div>

  salesByDateChart: (data)->
    return null unless data and find data, (d)->d.total_amount_net
    <ResponsiveContainer height={200} width={'100%'}>
      <ComposedChart data={data} margin={top: 20, right: 0, left: 0, bottom: 5}>
        <XAxis dataKey="date" hide={true}/>
        <Tooltip/>
        <Bar type="monotone" dataKey="total_amount_net" fill="#28AFFA"/>
      </ComposedChart>
    </ResponsiveContainer>

  reportExports: ->
    reports_by_type =
      csv:
        guests: 'Guestlist'
        attendees: 'Attendees'
        orders: 'Orders'
        cost_items: 'Tickets'
        add_ons: 'Add-Ons'
        cancellations: 'Cancellations and Refunds'
        events: 'Ticket Type - Timeslot Level'
        approvals: 'Requires Approval'
        check_ins: 'Check-Ins'
        chargebacks: 'Chargebacks'
        rebates: 'Rebates'
        timeslot_changes: 'Timeslot Changes'
        admin_waitlist: 'Waitlist Subscribers'
      pdf:
        ticket_counts: 'Ticket Counts'
        tm_audit: 'TM Audit'
        event_summary: 'Event Summary'
        service_invoice: 'Service Invoice'
        rebate_invoice: 'Rebate Invoice'
        audit: 'Audit'
        box_office: 'Box Office'

    <div className="report-exports">
      <h2>Report Exports</h2>

      <div className="alert alert-warning" role="alert">
        You must be logged into <a href="universe.com">universe.com</a> as someone with permission to view these reports.
      </div>

      <ul>
        {for ext, reports of reports_by_type
          for key, name of reports
            <li key={"#{key}-report-#{ext}"}>
              <a className="btn btn-default" href={"#{process.env.WEB}/listing_reports/#{@props.listing.id}/#{key}.#{ext}"}>
                <FaCloudDownload/>
                {name} Report
              </a>
            </li>
        }
        <li key={"audit-extended-report-pdf"}>
          <a className="btn btn-default" href={"#{process.env.WEB}/listing_reports/#{@props.listing.id}/audit.pdf?extended=true"}>
            <FaCloudDownload/>
            {"Extended Audit Report"}
          </a>
        </li>
        <li key={"invoices-pdf"}>
          <a className="btn btn-default" href={"#{process.env.WEB}/listings/#{@props.listing.id}/invoices.pdf"}>
            <FaCloudDownload/>
            {"Invoice Export"}
          </a>
        </li>
      </ul>
    </div>
